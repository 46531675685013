
export const environment = {
  production: false,
  hmr: false,
  apiUrl:   '/',
  // apiUrl:   'http://localhost:3500/',
  validEmail: '/CheckEmailUniqueness',
  validPh : '/CheckPhoneUniqueness',
  validId :'/CheckNationalIdUniqueness',
  validPasp : '/CheckPassportUniqueness',
  // apiUrl:  '/',
  AwardForm: 'https://www.ithra.com/'
};


