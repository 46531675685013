import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {BASE_URL,VALID_Ph, VALID_Email, VALID_Id, VALID_Pass} from '../constants/constant';
import {TokenService} from '../auth/services/token.service';
import {Toast, ToasterMessagesTypes} from '../decorators/toast.decorator';
import {ProgressBar} from '../decorators/progress-bar.decorator';



@Injectable()
export class CreateUserService {

  private urlAuto = `${BASE_URL}api/Autocomplete`;
  private urlAccount = `${BASE_URL}api/Account`;

  constructor(private http: HttpClient,
              private tokenService: TokenService) {
  }

  getProfile() {
    const url = `${BASE_URL}api/public/volunteer/basicProfile`;
    if (this.tokenService.getToken() != null) {
      return this.http.get(url);
    }
    return Observable.of(null);
  }

  @ProgressBar()
  @Toast(ToasterMessagesTypes.SuccessfullyRegistered)
  createUser(userProfile) {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    const url = `${BASE_URL}api/public/joinUs`;
    return this.http.post(url, userProfile, {headers: headers});
  }

  // checkEmail(email) {
  //   return this.http.post(`${this.urlAccount + VALID_Email}`, email);
  // }

  checkEmail(emailData: { email: string; captcha: string }) {
    return this.http.post(`${this.urlAccount + VALID_Email}`, emailData);
  }
  

  checkPhone(phone) {
    return this.http.post(`${this.urlAccount+ VALID_Ph}`, phone);
  }

  checkNationalID(number) {
    return this.http.post(`${this.urlAccount + VALID_Id}`, number);
  }

  checkPassportID(number) {
    return this.http.post(`${this.urlAccount+VALID_Pass}`, number);
  }


  getCites(): Observable<any> {
    return this.http.get(`${this.urlAuto}/city`);
  }

  getProfessions(): Observable<any> {
    return this.http.get(`${this.urlAuto}/profession`);
  }

  getSkills(): Observable<any> {
    return this.http.get(`${this.urlAuto}/skill`);
  }

  getJoiningReason(): Observable<any> {
    return this.http.get(`${this.urlAuto}/joiningReason`);
  }

  getGender(): Observable<any> {
    return this.http.get(`${this.urlAuto}/gender`);
  }

  getNationality(): Observable<any> {
    return this.http.get(`${this.urlAuto}/nationality`);
  }

  getMaritalStatus(): Observable<any> {
    return this.http.get(`${this.urlAuto}/maritalStatus`);
  }

  getDayOfWeek(): Observable<any> {
    return this.http.get(`${this.urlAuto}/dayOfWeek`);
  }

  getParticipationTime(): Observable<any> {
    return this.http.get(`${this.urlAuto}/participationTime`);
  }
}
