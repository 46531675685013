import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroupDirective, Validators} from '@angular/forms';

import {UserProfileDataService} from '../user-profile-data.service';
import {CreateUserService} from '../../../../services/create-user.service';
import {Subject} from 'rxjs/Subject';
import {AccountProfileModel} from '../user-profile.model';
import {CommonValidators} from '../../../../common-validators';
import {RegexpPattern, ErrorCodes} from '../../../../constants/constant';

@Component({
  selector: 'mvms-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit, OnDestroy {
  initialEmail: string;
  initialPhoneNo: string;
  model: AccountProfileModel;
  accountForm = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(new RegExp(RegexpPattern.email))]],
    emailRecaptchaResponse: [null], 
    emailVerified :[''], 
    phoneNo: ['', [Validators.required, Validators.pattern(new RegExp(RegexpPattern.phone))]],
    currentPassword: [null, [Validators.minLength(8), Validators.maxLength(50), Validators.pattern(new RegExp(RegexpPattern.password))]],
    newPassword: ['', [Validators.minLength(8), Validators.maxLength(50)]],
    passwordConfirmation: ['', [Validators.minLength(8), Validators.maxLength(50)]],
    isPasswordChanged: [false],
  }, {validator: CommonValidators.isMatch('newPassword', 'passwordConfirmation')});
  private componentDestroyed: Subject<any> = new Subject();
  private checkBeforeSave: boolean;
  private phoneCheckedValid: boolean;
  private phoneChecking: boolean;
  public emailVerified: boolean = false;
  public captchaResponseEmail = false;
  public mailChecked: boolean = true;
  captchaKey: string;
  @ViewChild('form') form: FormGroupDirective;

  constructor(
    private fb: FormBuilder,
    private userProfileDataService: UserProfileDataService,
    private createUserService: CreateUserService) {
  }

  ngOnInit() {
    this.captchaKey = window.location.host.startsWith('localhost')
    ? '6LeEUzQaAAAAAJC2noxFx1L6aL2yPx16EacWU4o1' : '6LeEUzQaAAAAAJC2noxFx1L6aL2yPx16EacWU4o1';
    this.accountForm.controls.email.setErrors(null);
    this.userProfileDataService.getAccountProfile().subscribe((model) => {
      this.model = model;
      this.initialEmail = model.email;
      this.initialPhoneNo = model.phoneNo;
      
     this.patchModelInForms();
    });

    this.accountForm.get('newPassword').disable();
    this.accountForm.get('passwordConfirmation').disable();
    this.accountForm.get('currentPassword').valueChanges.takeUntil(this.componentDestroyed).subscribe((value: number) => {
      if (value) {
        this.accountForm.get('newPassword').enable();
        this.accountForm.get('passwordConfirmation').enable();
        this.setRequiredValidator('accountForm', ['newPassword', 'passwordConfirmation'], [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(new RegExp(RegexpPattern.password))]);
      } else {
        this.clearValidators('accountForm', ['newPassword', 'passwordConfirmation']);
        this.accountForm.setErrors(null);
        this.accountForm.updateValueAndValidity();
        this.accountForm.get('newPassword').disable();
        this.accountForm.get('passwordConfirmation').disable();
      }
      this.accountForm.updateValueAndValidity();
    });

    this.accountForm.valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
      if (this.accountForm.hasError('isMatch')) {
        this.accountForm.controls.passwordConfirmation.setErrors({isMatch: true});
      } else {
        this.accountForm.controls.passwordConfirmation.setErrors(null);
        if ((this.accountForm.controls.passwordConfirmation.value === null || this.accountForm.controls.passwordConfirmation.value === '') && this.accountForm.controls.currentPassword.dirty && this.accountForm.controls.currentPassword.value !== '') {
          this.accountForm.controls.passwordConfirmation.setErrors({required: true});
        }
      }
    });

    // this.accountForm.get('phoneNo').valueChanges.takeUntil(this.componentDestroyed).subscribe(() => {
    //   this.phoneChecked = false;
    // });
  }

  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.unsubscribe();
    this.accountForm.controls['emailRecaptchaResponse'].reset();
  }

  setRequiredValidator(form, fieldsArr, validatorsArr) {
    for (let i = 0; i < fieldsArr.length; i++) {
      this[form].get(fieldsArr[i]).setValidators(validatorsArr);
      this[form].get(fieldsArr[i]).updateValueAndValidity();
    }
  }

  clearValidators(form, fieldsArr) {
    for (let i = 0; i < fieldsArr.length; i++) {
      this[form].get(fieldsArr[i]).clearValidators();
      this[form].get(fieldsArr[i]).updateValueAndValidity();
    }
  }

  public patchModelInForms() {
    this.accountForm.patchValue(this.model);
    this.accountForm.updateValueAndValidity();
  }

  public update() {
    if (this.accountForm.controls.newPassword.valid || this.accountForm.controls.passwordConfirmation.valid || this.accountForm.controls.currentPassword.valid) {
      this.accountForm.get('isPasswordChanged').setValue(true);
    } else {
      this.accountForm.get('isPasswordChanged').setValue(false);
    }
    if (this.accountForm.valid) {
      const model = this.accountForm.value;
      if (!this.accountForm.value.currentPassword) {
        model.isPasswordChanged = false;
      }
      this.userProfileDataService.updateAccountProfile(model).subscribe(() => {
        this.initialEmail = model.email;
        this.initialPhoneNo = model.phoneNo;
      }, error => {
        if (error.error.code === ErrorCodes.invalidPassword) {
          this.accountForm.controls.currentPassword.setErrors({'invalid': true});
        }
      });
    }
  }

  
  checkEmailUnique() {
    const email = this.accountForm.get('email')?.value;
    const emailRecaptchaResponse = this.accountForm.get('emailRecaptchaResponse')?.value;
  
    if (!emailRecaptchaResponse || !email ) {
      this.captchaResponseEmail = false;
      if (!this.accountForm.controls.email.hasError('required')) {
        this.accountForm.controls.email.setErrors({ required: true });
      }
      return; 
    }
   
    const emailData = {
      email: this.accountForm.controls.email.value,
      captcha: emailRecaptchaResponse,
    };
  
    if (this.accountForm.controls.email.dirty &&
      (!
        (this.accountForm.controls.email.hasError('notUnique') ||
          this.accountForm.controls.email.hasError('pattern') ||
          this.accountForm.controls.email.hasError('required'))
      )) {
      this.createUserService.checkEmail(emailData).subscribe((res: any) => {
        if (res.isUnique) {
          this.mailChecked = true;
          this.captchaResponseEmail = true;
          this.emailVerified = true;
          this.accountForm.controls.email.setErrors(null);
         
        } else {
          this.accountForm.controls.email.setErrors({ 'notUnique': true });

        }
      });
    }
  }

  EmailModify()
  {    
    this.mailChecked = false;
    const email = this.accountForm.get('email')?.value;
    
    if (email && email.trim() !== '' ) {
      if(!
        (this.accountForm.controls.email.hasError('notUnique') ||
        this.accountForm.controls.email.hasError('pattern') ||
        this.accountForm.controls.email.hasError('required'))
       ){
    this.accountForm.controls.email.setErrors({ 'verifyEmail': true });    
      }
    this.mailChecked = false;
    this.captchaResponseEmail = false;
    this.emailVerified = false;
    this.accountForm.controls['emailRecaptchaResponse'].reset();
    }
  }
  

  // checkEmail() {
  //   if (this.accountForm.controls.email.dirty && this.accountForm.controls.email.valid && this.accountForm.controls.email.value !== this.initialEmail) {
  //     this.createUserService.checkEmail({email: this.accountForm.controls.email.value}).subscribe((res: any) => {
  //       if (!res.isUnique) {
  //         this.accountForm.controls.email.setErrors({'notUnique': true});
  //       }
  //     });
  //   }
  // }

  checkPhoneOnBlur() {
    this.checkBeforeSave = false;
    this.phoneCheckedValid = false;
    this.checkPhone();
  }

  updateForm() {
    this.checkBeforeSave = true;
    if (!this.phoneChecking) {
      this.update();
    }
  }

  checkPhone() {
    if (this.accountForm.controls.phoneNo.dirty && this.accountForm.controls.phoneNo.valid && !this.phoneCheckedValid && this.accountForm.controls.phoneNo.value !== this.initialPhoneNo) {
      this.phoneChecking = true;
      this.createUserService.checkPhone({phoneNo: this.accountForm.controls.phoneNo.value}).subscribe((res: any) => {
        if (!res.isValid) {
          this.accountForm.controls.phoneNo.setErrors({'notValid': true});
          this.phoneCheckedValid = false;
        } else if (!res.isUnique) {
          this.accountForm.controls.phoneNo.setErrors({'notUnique': true});
          this.phoneCheckedValid = false;
        } else {
          if (this.accountForm.value.phoneNo === this.model.emergencyContact) {
            this.accountForm.controls.phoneNo.setErrors({'equal': true});
            this.phoneCheckedValid = false;
          }
          this.phoneCheckedValid = true;
          this.phoneChecking = false;
          this.accountForm.updateValueAndValidity();
          if (this.checkBeforeSave) {
            this.update();
          }
        }
      });
    }
  }

  reset() {
    this.patchModelInForms();
    this.form.resetForm(this.model);
  }
}
